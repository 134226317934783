<template>
  <div style="padding: 16px">
    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="18">
          <el-button type="primary" @click="newBtn">新建</el-button>
        </el-col>
        <el-col :span="6">
          <el-input placeholder="请输入内容" v-model="queryInfo.condition.queryKey" class="input-with-select" clearable>
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%" :height="height">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column label="产品编号" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.systemId }}</template>
        </el-table-column>
        <el-table-column label="产品名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.productName }}</template>
        </el-table-column>
        <el-table-column label="分类名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.categoryName }}</template>
        </el-table-column>
        <el-table-column label="标题" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.title }}</template>
        </el-table-column>
        <el-table-column label="封面图片" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-image style="width: 80px; height: 80px" :preview-src-list="scope.row.coverImage.split(',')"
              :src="scope.row.coverImage">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="排序" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.seq }}</template>
        </el-table-column>
        <el-table-column label="风格" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.style }}</template>
        </el-table-column>
        <el-table-column label="房型" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.houseType }}</template>
        </el-table-column>
        <el-table-column label="价格" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.price }}</template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.createDate }}</template>
        </el-table-column>
        <el-table-column label="操作" width="135" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row.productCode)" size="small">编辑</el-button>
            <el-popconfirm title="确定删除吗？" @confirm="handleDelte(scope.row.productCode)">
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage" :page-sizes="[10, 20, 30, 40]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="labelType == 'add' ? '新增' : '编辑'" :visible.sync="showDialog" width="70%"
      class="mainPublishDialog" @close="bulletBoxClose">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" :key="timer">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="产品名称：" prop="siteName">
              <el-input v-model="form.productName" placeholder="请输入产品名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分类名称：" prop="siteName">
              <el-cascader @change="changeProduct" v-model="form.categoryCode" style="width: 100%"
                :options="productList" :props="{
                  expandTrigger: 'click',
                  value: 'categoryCode',
                  label: 'categoryName',
                  children: 'children',
                  checkStrictly: true,
                }" :show-all-levels="false"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标题：" prop="siteName">
              <el-input v-model="form.title" placeholder="请输入标题名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序：" prop="siteName">
              <el-input v-model="form.seq" placeholder="请输入排序"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="风格：" prop="siteName">
              <el-input v-model="form.style" placeholder="请输入风格"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="房型：" prop="siteName">
              <el-input v-model="form.houseType" placeholder="请输入房型"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="价格：" prop="siteName">
              <el-input v-model="form.price" type="number" placeholder="请输入价格"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="跳转产品编号：" prop="skipSystemId">
              <el-input v-model="form.skipSystemId"  placeholder="请输入跳转产品编号"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="封面图片：" prop="siteName">
              <el-upload class="avatar-uploader" :action="$uploadURL" :show-file-list="true"
                :on-success="handleSuccess">
                <img v-show="form.coverImage" :src="form.coverImage" class="avatar" />
                <div v-show="!form.coverImage">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div v-if="labelType === 'edit'">
        <el-col :span="24" style="height: 35px; width: 100%; font-size: 18px; line-height: 35px">
          详细信息：
        </el-col>
        <el-col :span="24" style="margin-left: 35px">
          <el-button type="primary" @click="increaseClass" style="margin-bottom: 8px; margin-left: 3px">添加分类</el-button>
          <el-button type="primary" @click="editTabs" v-if="!isEditTabs"
            style="margin-bottom: 8px; margin-left: 3px">修改分类</el-button>
          <el-button type="primary" @click="editTabsComplete" v-if="isEditTabs"
            style="margin-bottom: 8px; margin-left: 3px">确定修改</el-button>
          <el-tabs ref="tabs" :tab-position="tabPosition" v-model="editableTabsValue" type="card" :closable="isEditTabs"
            @tab-click="clickTab" @tab-remove="removeTab">
            <el-tab-pane :key="item.attrCode" v-for="item in editableTabs" :label="item.attrName" :name="item.attrCode">
              <template slot="label">
                <el-input class="tabsInput" v-model="item.attrName" v-if="isEditTabs" @input="inputChange">
                </el-input>
              </template>
              <!-- 新增 -->
              <!-- 短视频 -->
              <template v-if="item.attrType === '1'">
                <div style="width: 90%">
                  <div class="tabPaneOnetop">
                    <el-checkbox v-model="videoCheckAll" @change="videoCheckAllChange"></el-checkbox>
                    <el-button type="text" @click="videoBatchDelete">批量删除</el-button>
                    <el-button type="text" @click="videoBtn"><i class="el-icon-upload2"></i> 上传</el-button>
                  </div>
                  <div class="tabPanOnebtm" style="width: 100%; height: 300px; overflow-y: auto">
                    <ul v-if="coverVideoList.length > 0">
                      <li v-for="item in coverVideoList" :key="item.id">
                        <el-checkbox v-if="videoCheckAll" v-model="item.checked"></el-checkbox>
                        <video class="video" controls :src="item.itemFileUrl"></video>
                      </li>
                    </ul>
                    <el-empty v-else :image-size="180"></el-empty>
                    <div></div>
                  </div>
                </div>
              </template>
              <!-- VR -->
              <template v-else-if="item.attrType === '2'">
                <div style="width: 90%">
                  <div class="tabPaneOnetop">
                    <el-button type="text" @click="VRBatchDelete">删除</el-button>
                    <el-button type="text" @click="vrBtn"><i class="el-icon-upload2"></i> 上传</el-button>
                  </div>
                  <div class="tabPanTwobtm">
                    <div style="display: flex; width: 300px; margin-left: 30px">
                      VR资源地址：<el-input :disabled="true" v-model="vrShowForm.itemFileUrl" placeholder="资源地址"
                        style="width: 70%"></el-input>
                    </div>
                    <div class="vrImg">
                      封面图片：
                      <img v-if="vrShowForm.showImage" :src="vrShowForm.showImage" alt="" />
                      <el-empty v-else :image-size="150"></el-empty>
                    </div>
                  </div>
                </div>
              </template>
              <!-- 效果图 -->
              <template v-else>
                <div style="width: 90%">
                  <div class="tabPaneOnetop">
                    <div style="width:400px;margin-right:20px;" class="flex">
                      <span style="width:150px;font-size:12px;">跳转产品编号：</span>
                      <el-input v-model="skipSystemId"></el-input>
                      <el-button type="text" @click="saveJumpId">保存</el-button>
                    </div>
                    <el-checkbox v-model="renderCheckAll" @change="renderCheckAllChange"></el-checkbox>
                    <el-button type="text" @click="renderBatchDelete">批量删除</el-button>
                    <el-button type="text" @click="renderBtn"><i class="el-icon-upload2"></i> 上传</el-button>
                  </div>
                  <div class="tabPanThreebtm">
                    <div style="width: 100%; overflow-y: auto">
                      <ul class="tabPanUl" v-if="renderImageList.length > 0">
                        <li v-for="(item, index) in renderImageList" :key="index">
                          <el-checkbox v-if="renderCheckAll" v-model="item.checked"></el-checkbox>
                          <img :src="item.itemFileUrl" alt="" />
                          <div style="text-align: center; width: 100%">
                            {{ item.itemName }}
                          </div>
                        </li>
                      </ul>
                      <el-empty v-else :image-size="150"></el-empty>
                    </div>
                  </div>
                </div>
              </template>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </div>
      <span slot="footer">
        <el-button size="small" @click="homePageClose">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>

    <!-- 效果图 -->
    <el-dialog title="图片上传" :visible.sync="renderDialog" width="800px" destroy-on-close>
      <el-row>
        <el-form ref="houseForm" :model="renderForm" label-width="120px">
          <el-form-item>
            <el-upload :action="$uploadURL" list-type="picture-card" :on-preview="renderPreviewImg"
              :on-remove="renderRemoveImg" :on-success="renderUploadImg" :limit="fileLimit" :on-exceed="uploadOverLimit"
              :multiple="true">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-row>
      <el-dialog :visible.sync="renderIsPreview" :modal="false">
        <el-image width="100%" :src="renderPreviewItem" alt="" />
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="renderClose">取 消</el-button>
        <el-button type="primary" @click="renderSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加分类 -->
    <el-dialog title="添加分类" :visible.sync="increaseDialog" width="30%" destroy-on-close>
      <el-form :data="vrForm">
        <el-form-item label="名称：" label-width="120px">
          <el-input v-model="increaseForm.increaseName" placeholder="请输入名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="increaseClose">取 消</el-button>
        <el-button type="primary" @click="increaseSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 短视频上传表单 -->
    <el-dialog title="短视频上传" :visible.sync="videoDialog" width="500px" destroy-on-close>
      <el-form :data="videoForm" label-width="120px">
        <!-- <el-form-item label="短视频分类：">
          <el-select
            v-model="videoForm.videoCategoryCode"
            placeholder="请选择"
            style="width: 100%"
            @change="chooseName"
            ref="category"
          >
            <el-option
              v-for="item in videoOptions"
              :key="item.videoCategoryCode"
              :label="item.videoCategoryName"
              :value="item.videoCategoryCode"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="短视频名称：">
          <el-input v-model="videoForm.itemName" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="短视频标题：">
          <el-input v-model="videoForm.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="短视频排序：">
          <el-input v-model="videoForm.seq" placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item label="短视频上传：">
          <el-upload class="avatar-uploader" :action="$uploadURL" :show-file-list="true" :on-success="videoSuccess"
            :limit="1" :on-remove="removeVideo" :on-change="videohandleChange" ref="picUpload">
            <video v-if="videoForm.itemFileUrl" class="avatarVideo" :src="videoForm.itemFileUrl"></video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="封面图片：" prop="coverImage" label-width="120px">
          <el-upload class="avatar-uploader" :action="$uploadURL" :show-file-list="true"
            :on-success="videoImageSuccess">
            <img v-show="videoForm.showImage" :src="videoForm.showImage" class="avatar" />
            <div v-show="!videoForm.showImage">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="videoClose">取 消</el-button>
        <el-button type="primary" @click="videoSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- vr地址 -->
    <el-dialog title="VR上传" :visible.sync="vrDialog" width="30%" destroy-on-close>
      <el-form :data="vrForm">
        <el-form-item label="VR资源地址：" label-width="120px">
          <el-input v-model="vrForm.itemFileUrl" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="封面图片：" prop="coverImage" label-width="120px">
          <el-upload class="avatar-uploader" :action="$uploadURL" :show-file-list="true" :on-success="vrSuccess">
            <img v-show="vrForm.showImage" :src="vrForm.showImage" class="avatar" />
            <div v-show="!vrForm.showImage">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="vrClose">取 消</el-button>
        <el-button type="primary" @click="vrSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增分类 -->
    <el-dialog title="图片上传" :visible.sync="addClassDialog" width="800px" destroy-on-close>
      <el-form :data="addClassForm">
        <el-form-item label="跳转产品编号：" label-width="80px">
          <el-input v-model="addClassForm.skipSystemId"></el-input>
        </el-form-item>
        <el-form-item label="图片上传：" label-width="120px">
          <el-upload :action="$uploadURL" list-type="picture-card" :on-preview="previewImg"
            :on-remove="removeAddClassImg" :on-success="uploadAddClassImg" :limit="fileLimit"
            :on-exceed="uploadOverLimit" :multiple="true">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-dialog :visible.sync="isPreview" :modal="false">
          <el-image width="100%" :src="previewItem" alt="" />
        </el-dialog>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addClassClose">取 消</el-button>
        <el-button type="primary" @click="addClassSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增图片编辑 -->
    <el-dialog title="编辑图片" :visible.sync="addClassImgDialog" width="30%" destroy-on-close v-if="!addClassDeleImg">
      <el-form :data="addClassImgform">
        <el-form-item label="图片：" prop="coverImage" label-width="120px">
          <el-upload class="avatar-uploaderImage" :action="$uploadURL" :show-file-list="true"
            :on-success="addclassChangeImgSuccess">
            <img v-show="addClassImgform.itemFileUrl" :src="addClassImgform.itemFileUrl" class="avatarImage" />
            <div v-show="!addClassImgform.itemFileUrl">
              <i class="el-icon-plus avatar-uploaderImage-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addImgClose">取 消</el-button>
        <el-button type="primary" @click="addImgSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectTypeIndex: 0, //当前选中分类索引
      isEditTabs: false, //分类标签是否可编辑
      delIdList: [],
      momey: "",
      labelType: "add",
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {},
      },
      form: {
        productName: "", // 产品名称
        categoryCode: "", // 分类名称
        title: "", // 标题
        seq: "", // 排序
        itemFileUrl: "", // 封面图片
        style: "", // 风格
        houseType: "", // 房型
        price: "", // 价格
        skipSystemId: '',//跳转名称
      },
      // 短视频
      videoForm: {
        itemName: "",
        title: "",
        seq: "",
        attrCode: "",
        // videoCategoryCode: "",
        itemFileUrl: "",
        showImage: "",
        isPortraitScreen: "",
      },
      videoOptions: [],
      videoAttrCode: "", // 短视频attrCode
      coverVideoList: [], // 短视频列表
      // vr
      vrForm: {
        itemFileUrl: "",
        attrCode: "",
        showImage: "",
        id: "",
      },
      vrAttrCode: "", // vrAttrCode
      // vr回显
      vrShowForm: {
        itemFileUrl: "",
        showImage: "",
      },
      // 全屋空间
      houseForm: {
        itemType: "", // 全屋空间分类名称
        fileList: [], // 分类图片
        attrCode: "",
      },
      houseAttrCode: "", // 全屋空间AttrCode
      // 效果图
      renderForm: {
        itemType: "", // 效果图名称
        fileList: [], // 效果图
        attrCode: "",
      },
      renderAttrCode: "", // 效果图AttrCode
      // 柜体1
      cabinetForm: {
        attrCode: "",
        fileList: [],
      },
      cabinetAttrCode: "", // 柜体AttrCode
      // 柜体2
      cabinetShowForm: {
        attrCode: "",
        fileList: [],
      },
      // 柜体展示
      cabinetFace: {
        attrCode: "",
        cabinetMainTitle: "", // 标题
        cabinetMainImage: "", // 主图
        cabinetImageList: [], // 底部小图
        colorImageList: [], // 右侧图片
      },
      // 柜体编辑图片
      cabinetImgform: {
        id: "",
        itemFileUrl: "",
        itemName: "",
      },
      // 内部
      internalForm: {
        internalName: "", // 选中的名字
        parentCode: "", // 选中的code
        productCode: "",
        internalSmallList: [], // 底部小图
        internalMain: "",
        internalMainTitle: "", // 标题
        internalMainList: [], // 右边小图
        internalShowImg: "",
        internalNameList: [], // 柜体数组
      },
      internalAttrCode: "", // 内部AttrCode
      // 内部表单
      interForm: {
        attrCode: "",
        fileList: [],
        parentCode: "",
        internalName: "", // 柜体名称
        internalNameList: [], // 柜体数组
      },

      // 内部编辑图片
      interImgform: {
        itemFileUrl: "",
        id: "",
        itemName: "",
      },
      // 柜门
      cabDoorForm: {
        attrCode: "",
        fileList: [],
      },
      cabDoorAttrCode: "", // 柜门AttrCode
      // 柜门显示
      cabDoorFaceForm: {
        cabImgList: [],
        cabDoorMain: "",
        cabDoorMainTitle: "",
        cabSmallImgList: [],
        cabDoorShowImg: "",
      },
      // 柜门编辑图片
      cabDoorImgform: {
        id: "",
        itemFileUrl: "",
        itemName: "",
      },

      // 新增页面表单
      addClassForm: {
        fileList: [],
        attrCode: "",
      },

      // 新增页面显示
      addClassFaceForm: {
        addClassMain: "", // 主图片
        addClassMainTitle: "", // 主标题
        addClassRightImgList: [], // 右侧图片
        addClassBtmImgList: [], // 底部图片
        addClassShowImg: "", // 矩形小图
      },
      addClassAttrCode: "",
      addClassAttrType: "",
      // 新增页面编辑图片
      addClassImgform: {
        id: "",
        itemFileUrl: "",
        itemName: "",
      },

      // 添加分类
      increaseForm: {
        increaseName: "",
      },

      addClassDialog: false, // 新增分类图片
      increaseDialog: false, // 添加分类
      dialogFullScreen: false, // 是否为全屏
      showDialog: false,
      videoDialog: false, // 短视频
      vrDialog: false, // vr
      houseDialog: false, // 全屋空间
      houseIsPreview: false, // 全屋空间预览
      renderDialog: false, // 效果图
      renderIsPreview: false, // 效果图预览
      cabinetFileDialog: false, // 柜体右侧
      cabinetShowDialog: false, // 柜体左侧
      canbinetImgDialog: false, // 柜体右边编辑
      canbinDeleImg: false, // 柜体右边删除按钮
      internalDialog: false, // 内部
      interImgDialog: false, // 内部右边编辑
      deleImage: false, // 内部删除按钮
      cabDoorDialog: false, // 柜门
      cabdoorImgDialog: false, // 柜门右边编辑
      cabdoorDeleImg: false, // 柜门右边删除按钮
      isPreview: false, // 柜体图片预览
      addClassImgDialog: false, // 新增图片编辑
      addClassDeleImg: false, // 新增删除按钮
      // 预览的图片
      previewItem: "",
      // 文件上传数量限制
      fileLimit: 30,
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0,
      tabPosition: "left",
      // 全屋空间预览图片
      housePreviewItem: "",
      // 效果图预览的图片
      renderPreviewItem: "",
      // 文件类型
      fileType: "",
      // 文件上传数量限制
      fileLimit: 30,
      editableTabsValue: "",
      editableTabs: [],
      tabIndex: 7,
      timer: "",
      inputFlag: false,
      itemFileUrl: [],
      activeName: "1", // 默认选择
      houseList: [], // 全屋空间
      houseIndex: "1",
      renderList: [], // 效果图
      renderIndex: "1",
      houseName: "1", // 全屋空间tabs默认选中
      renderName: "1", // 效果图tabs默认选中
      houseImageList: [], // 全屋空间图片展示
      renderImageList: [], // 效果图展示
      renderOptions: [],
      value: "",
      productList: [], // 产品分类名称

      VRCheckAll: false, // VR全选
      videoCheckAll: false, // 短视频全选
      houseCheckAll: false, // 全屋空间全选
      renderCheckAll: false, // 效果图全选
      videoIndeterminate: true,



      //画矩形相关参数
      rectangles: [], // 保存矩形坐标的数组
      canvasContext: null,
      isDrawing: false,
      startX: 0,
      startY: 0,
      currentX: 0,
      currentY: 0,
      drawingMode: false,
      showFloatingImage: false,
      floatingImageLoaded: false,
      showButtonFlag: true,
      floatingImageList: [],
      videoIdList: [],
      videoObject: {},
      houseObject: {},
      houseIdList: [],
      renderObject: {},
      renderIdList: [],
      interObject: {
        idList: [],
      },
      canbinetObject: {
        idList: [],
      },
      cabdoorObject: {
        idList: [],
      },
      addclassObject: {
        idList: [],
      },
      effectList: [],
      effectName: "",

      skipSystemId: '',   //跳转id
    };
  },

  created() {
    this.getList();
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    //跳转id
    saveJumpId() {
      const data = {}
      data.skipSystemId = this.skipSystemId
      data.id = this.editableTabs[this.selectTypeIndex].id
      this.$http.post('/productExtAttr/update', data).then(res => {
        if (res.data.code == 200) {
          this.skipSystemId = res.data.data.skipSystemId
          this.editableTabs[this.selectTypeIndex] = res.data.data
          this.$notify.success({
            title: "提示",
            message: "保存成功",
            showClose: true,
          });
        }
      })
    },
    addImgSubmit() { },
    //上传超出限制
    uploadOverLimit() {
      this.$notify.error({
        title: "提示",
        message: "一次最大上传数不能超过30张！",
        showClose: true,
      });
    },
    moneyChange(e) {
      console.log("e", e);
      console.log("money", this.money);
    },

    // 短视频批量删除全选
    videoCheckAllChange(val) {
      // console.log(val);
      this.coverVideoList.forEach((item) => {
        item.checked = val;
      });
    },

    // 短视频批量删除
    videoBatchDelete() {
      var that = this;
      that.videoIdList = [];
      that.coverVideoList.forEach((item) => {
        if (item.checked) {
          that.videoIdList.push(item.id);
        }
      });
      that.videoObject.idList = that.videoIdList;
      // console.log(that.videoIdList);
      that.$http
        .post("/productExtAttrItem/deleteBatch", that.videoObject)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            that.clickTab({ index: that.selectTypeIndex });
            that.videoCheckAll = false;
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },
    // 效果图全选
    renderCheckAllChange(val) {
      console.log('全选', val);
      this.renderImageList.forEach((item) => {
        item.checked = val;
      });
    },

    // 效果图批量删除
    renderBatchDelete() {
      var that = this;
      that.renderIdList = [];
      console.log('renderImageList', that.renderImageList);
      that.renderImageList.forEach((item) => {
        if (item.checked == true) {
          that.renderIdList.push(item.id);
        }
      });
      that.renderObject.idList = that.renderIdList;
      that.$http
        .post("/productExtAttrItem/deleteBatch", that.renderObject)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            //   that.renderTabs(that.renderIndex)
            that.clickTab({ index: that.selectTypeIndex });
            that.renderCheckAll = false;
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },


    // VR删除
    VRBatchDelete() {
      var that = this;
      that.$http
        .post("/productExtAttrItem/delete", { "id": that.vrForm.id })
        .then(function (response) {
          console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            //   that.renderTabs(that.renderIndex)
            that.clickTab({ index: that.selectTypeIndex });
            that.renderCheckAll = false;
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 弹框关闭清空
    bulletBoxClose() {
      let totalTemp = this.total;
      let queryInfoTemp = this.queryInfo;
      let tableDataTemp = this.tableData;
      Object.assign(this.$data, this.$options.data());
      this.total = totalTemp;
      this.queryInfo = queryInfoTemp;
      this.tableData = tableDataTemp;
    },

    // // 添加分类清空
    incrCloseDialog() {
      this.increaseForm.increaseName = "";
    },

    // // 短视频清空
    videoCloseDialog() {
      //  this.videoForm = {};
      this.videoForm.itemName = "";
      this.videoForm.seq = "";
      // this.videoForm.videoCategoryCode = "";
      this.videoForm.itemFileUrl = "";
      this.videoForm.isPortraitScreen = "";
      this.videoForm.showImage = "";
      this.videoForm.title = "";
    },

    // // vr清空
    vrCloseDialog() {
      this.vrForm.itemFileUrl = "";
      this.vrForm.showImage = "";
      this.vrForm.attrCode = "";
    },

    // // 全屋空间清空
    houseCloseDialog() {
      this.houseForm.itemType = "";
      this.houseForm.fileList = [];
    },

    // // 效果图清空
    renderCloseDialog() {
      this.renderForm.itemType = "";
      this.renderForm.fileList = [];
    },

    // // 柜体1清空
    cabinetFileCloseDialog() {
      this.cabinetForm.fileList = [];
    },

    // // 柜体2清空
    cabinetShowCloseDialog() {
      this.cabinetShowForm.fileList = [];
    },

    // // 内部清空
    internalCloseDialog() {
      this.interForm.fileList = [];
    },

    // // 柜门清空
    cabDoorCloseDialog() {
      this.cabDoorForm.fileList = [];
    },

    // // 添加分类图片清空
    addClassCloseDialog() {
      this.addClassForm.fileList = [];
    },

    // 添加分类图片表单上传
    addClassBtn() {
      this.addClassDialog = true;
    },

    // 添加分类图片取消
    addClassClose() {
      this.addClassDialog = false;
      this.addClassCloseDialog();
    },

    // 添加分类图片确定
    addClassSubmit() {
      var that = this;
      that.addClassDialog = false;
      var formData = that.addClassForm;
      formData.attrCode = that.addClassAttrCode;
      that.$http
        .post("/productExtAttrItem/saveBatch", formData)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
            that.addClassClose();
            that.clickTab({ index: "0" });
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 删除添加分类图片
    removeAddClassImg(res, file, fileList) {
      // console.log(res);
      const index = this.addClassForm.fileList.findIndex((v) => {
        return v.fileName === res.fileName;
      });
      this.addClassForm.fileList.splice(index, 1);
    },

    // 添加分类图片上传成功
    uploadAddClassImg(res, file, fileList) {
      // console.log(res);
      // console.log(fileList);
      this.addClassForm.fileList.push(res.data);
    },

    // 添加分类名称打开
    increaseClass() {
      this.increaseDialog = true;
    },
    //点击修改分类按钮
    editTabs() {
      this.isEditTabs = true;
    },
    //点击确定修改按钮
    editTabsComplete() {
      const data = {};
      this.editableTabs.forEach((e) => {
        if (e.attrName == "") {
          this.$notify.error({
            title: "提示",
            message: "分类名称不能为空",
            showClose: true,
          });
          return;
        }
      });
      data.updateAttrList = this.editableTabs;
      data.delAttrCodeList = this.delIdList;
      data.productCode = this.form.productCode;
      this.$http.post("/productExtAttr/updateBatch", data).then((res) => {
        if (res.data.code == 200) {
          this.$notify.success({
            title: "提示",
            message: "修改成功",
            showClose: true,
          });
          this.editableTabsValue = this.editableTabs[0].attrCode;
          this.clickTab({ index: 0 });
          this.isEditTabs = false;
        }
      });
    },
    inputChange(event) {
      console.log(event);
      // 阻止事件冒泡
      // event.stopPropagation();
    },
    //删除分类回调
    removeTab(e) {
      console.log(e);
      const event = window.event;
      // 检查事件源是否为输入框
      if (event.type.toLowerCase() === "keydown") {
        return;
      }
      let list = JSON.parse(JSON.stringify(this.editableTabs));

      list.forEach((item, index) => {
        if (item.attrCode == e) {
          list.splice(index, 1);
          this.delIdList.push(item.attrCode);
        }
      });
      this.editableTabs = list;
    },
    // 添加分类名称表单关闭
    increaseClose() {
      this.increaseDialog = false;
      this.increaseForm = {};
    },

    // 添加分类名称表单确定
    increaseSubmit() {
      var that = this;
      that.$http
        .post("/productExtAttr/save", {
          productCode: that.form.productCode,
          attrName: that.increaseForm.increaseName,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "添加成功",
              showClose: true,
            });
            that.increaseDialog = false;
            that.edit(that.form.productCode);
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 选择上级名称
    chooseName(val) {
      // console.log('val',val);
      this.videoForm.videoCategoryCode = val;
      // console.log(this.videoForm.videoCategoryCode);

      this.$nextTick(() => {
        // console.log(this.$refs.category.selectedLabel);
        this.videoForm.videoCategoryName = this.$refs.category.selectedLabel;
      });
      // this.form.videoCategoryName = this.$refs.category
      // console.log(this.$refs.category)
    },
    // 获取产品分类
    getProduct() {
      var that = this;
      that.$http
        .post("/productCategory/queryTreeList", that.form)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.productList = that.getTreeList(response.data.data);
            // console.log(that.productList);
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    getTreeList(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          data[i].children = undefined;
        } else {
          this.getTreeList(data[i].children);
        }
      }
      return data;
    },

    // 产品分类名称选择
    changeProduct(val) {
      // console.log(val);
      if (val && val.length) {
        this.form.categoryCode = val[val.length - 1];
      } else {
        return;
      }

      // console.log(this.categoryCode);
    },

    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 256;
    },

    //获取数据
    getList() {
      var that = this;
      that.queryInfo.condition.productType = "2";
      that.$http
        .post("/product/list", that.queryInfo)
        .then(function (response) {
          // console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 保存
    handleSave() {
      var that = this;
      let data = {};
      data = that.form;
      data.productType = "2";
      that.$http
        .post("/product/save", that.form)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
            that.showDialog = false;
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) { });
    },

    // 关闭表单
    homePageClose() {
      this.showDialog = false;
    },

    //修改
    edit(row_productCode) {
      // console.log(row_productCode);
      // this.$router.push('/ProductDetails')
      var that = this;
      that.showDialog = true;
      that.labelType = "edit";
      that.getProduct();

      that.$http
        .post("/product/queryProductDetail", { productCode: row_productCode })
        .then(function (response) {
          // console.log(response,'=====');
          if (response.data.code == 200) {
            that.form = response.data.data;
            that.houseList = response.data.data.dictList;
            that.renderList = response.data.data.dictList;
            // that.renderOptions = response.data.data.dictList
            that.form.categoryCode = response.data.data.categoryCode;
            // console.log(that.form);
            that.internalForm.productCode = that.form.productCode;
            that.increaseForm.productCode = response.data.data.productCode;
            that.editableTabs = response.data.data.productAttrList;
            that.editableTabs.forEach(item => {
              if (item.attrType === '1') {
                that.videoForm.attrCode = item.attrCode
                that.videoAttrCode = item.attrCode
              } else if (item.attrType === '2') {
                that.vrForm.attrCode = item.attrCode
                that.vrAttrCode = item.attrCode
              }
            })
            that.renderForm.attrCode =
              response.data.data.productAttrList[0].attrCode;
            that.renderAttrCode =
              response.data.data.productAttrList[0].attrCode;
            that.editableTabsValue = that.editableTabs[0].attrCode;
            that.clickTab({ index: 0 });
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/product/delete", { productCode: id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true,
              });
              that.getList();
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      }
    },

    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {};
      this.getProduct();
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },

    // 图片上传
    handleSuccess(val) {
      // console.log(val);
      this.form.coverImage = val.data.url;
      this.timer = new Date().getTime();
    },

    labelChange() {
      this.inputFlag = true;
    },

    labelBlur() {
      this.inputFlag = false;
    },

    // // 点击时tabs触发
    clickTab(val) {
      // debugger
      var that = this;
      let index = val.index;
      that.selectTypeIndex = val.index;
      var attrObj = that.editableTabs[index];
      that.renderAttrCode = that.editableTabs[index].attrCode;
      that.skipSystemId = that.editableTabs[index].skipSystemId;
      // 短视频
      if (index == '0') {
        that.addClassAttrType = attrObj.attrType
        that.$http.post('/productExtAttrItem/queryList', { attrCode: that.videoAttrCode }).then(function (response) {
          // console.log("response", response);
          that.coverVideoList = []
          if (response.data.code == 200) {
            response.data.data.forEach(item => {
              let obj = {}
              obj.itemFileUrl = item.itemFileUrl
              obj.id = item.id
              obj.checked = false
              that.coverVideoList.push(obj)
            })
            // console.log(that.coverVideoList);
          } else {
            that.$notify.info({
              title: '提示',
              message: response.data.message,
              showClose: true
            })
          }
        })
      } else if (index == '1') {
        // vr
        var attrObj = that.editableTabs[index]
        that.addClassAttrType = attrObj.attrType
        that.$http.post('/productExtAttrItem/queryList', { attrCode: that.vrAttrCode }).then(function (response) {
          // console.log(response);
          console.log("response", response);
          if (response.data.code == 200) {
            response.data.data.forEach(item => {
              that.vrShowForm.showImage = item.showImage
              that.vrShowForm.itemFileUrl = item.itemFileUrl
              that.vrForm.id = item.id
            })
          } else {
            that.$notify.info({
              title: '提示',
              message: response.data.message,
              showClose: true
            })
          }
        })
      } else {
        // 效果图
        that.$http
          .post("/productExtAttrItem/queryList", { attrCode: attrObj.attrCode })
          .then((res) => {
            if (res.data.code == 200) {
              res.data.data.forEach((item) => {
                item.checked = false;
              });
              that.renderImageList = res.data.data;
            }
          });
      }


    },

    // 短视频上传表单
    videoBtn() {
      this.videoDialog = true;
      // this.getVideocategory();
    },

    // 获取短视频分类
    getVideocategory() {
      var that = this;
      that.$http.post("/videoCategory/queryList", {}).then(function (response) {
        // console.log(response);
        if (response.data.code == 200) {
          that.videoOptions = response.data.data;
          that.videoOptions.forEach((item) => {
            // console.log(item);
            item.videoCategoryCode = item.categoryCode;
            item.videoCategoryName = item.categoryName;
          });
          // console.log(that.videoOptions,'111');
        } else {
          that.$notify.info({
            title: "提示",
            message: response.data.message,
            showClose: true,
          });
        }
      });
    },

    // 移除视频
    removeVideo(val) {
      // console.log(val);
    },

    // 短视频上传
    videoSuccess(val) {
      // console.log(val);
      this.videoForm.itemFileUrl = val.data.url;
    },

    // 短视频上传改变
    videohandleChange(val) {
      var that = this;
      console.log(val);
      this.$nextTick(() => {
        let url = val.response?.data?.url;
        const newvideoUrl = url;
        const videoObj = document.createElement("video");
        videoObj.preload = "metadata";
        videoObj.src = newvideoUrl;
        videoObj.onloadedmetadata = function (evt) {
          URL.revokeObjectURL(newvideoUrl);
          let width = parseInt(videoObj.videoWidth); //获取视频的宽
          let height = parseInt(videoObj.videoHeight); //获取视频的高
          console.log("width, height:", width, height);
          if (width > height) {
            that.videoForm.isPortraitScreen = "0";
          } else if (width < height) {
            that.videoForm.isPortraitScreen = "1";
          }
          // console.log(that.videoForm.isPortraitScreen);
        };
      });
    },

    // 短视频封面上传
    videoImageSuccess(val) {
      this.videoForm.showImage = val.data.url;
    },

    // 短视频取消按钮
    videoClose() {
      this.videoDialog = false;
      this.videoCloseDialog();
    },

    // 短视频确定按钮
    videoSubmit() {
      var that = this;
      var formData = that.videoForm;
      formData.attrCode = that.videoAttrCode;
      that.$http
        .post("/productExtAttrItem/save", formData)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "上传成功",
              showClose: true,
            });
            that.clickTab({ index: "0" });
            that.videoClose();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // vr上传表单
    vrBtn() {
      this.vrDialog = true;
    },

    // vr封面图片上传
    vrSuccess(res, file) {
      this.vrForm.showImage = res.data.url;
      this.timer = new Date().getTime();
      // console.log(this.form.coverImage);
    },

    // vr取消按钮
    vrClose() {
      this.vrDialog = false;
      this.vrCloseDialog();
    },

    // vr确定按钮
    vrSubmit() {
      var that = this;
      that.vrDialog = false;
      var formData = that.vrForm;
      formData.attrCode = that.vrAttrCode;
      that.$http
        .post("/productExtAttrItem/save", that.vrForm)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "上传成功",
              showClose: true,
            });
            that.clickTab({ index: "1" });
            that.vrCloseDialog();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 全屋空间上传表单
    houseBtn() {
      this.houseDialog = true;
    },

    // 效果图上传表单
    renderBtn() {
      this.renderDialog = true;
    },

    // 效果图表单关闭
    renderClose() {
      this.renderDialog = false;
      this.renderCloseDialog();
    },

    // 效果图表单确认
    renderSubmit() {
      var that = this;
      that.renderDialog = false;
      var formData = that.renderForm;
      formData.attrCode = that.renderAttrCode;
      that.$http
        .post("/productExtAttrItem/saveBatch", formData)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "上传成功",
              showClose: true,
            });
            //   that.renderTabs(that.renderIndex)
            that.clickTab({ index: that.selectTypeIndex });
            that.renderCloseDialog();
          } else {
            that.renderCloseDialog();
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 效果图下拉选择
    renderSelect(val) {
      // console.log(val);
      this.renderForm.itemType = val;
    },

    // 效果图预览图片
    renderPreviewImg(file) {
      // console.log(file);
      this.renderIsPreview = true;
      this.renderPreviewItem = file.url;
    },

    // 效果图删除图片
    renderRemoveImg(res, file, fileList) {
      // console.log(res);
      const index = this.renderForm.fileList.findIndex((v) => {
        return v.fileName === res.fileName;
      });
      this.renderForm.fileList.splice(index, 1);
    },

    // 效果图上传
    renderUploadImg(res, file, fileList) {
      // console.log(res);
      // console.log(fileList);
      this.renderForm.fileList.push(res.data);
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.avatar-uploaderImage ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploaderImage .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploaderImage-icon {
  font-size: 28px;
  color: #8c939d;
  width: 160px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatarImage {
  width: 160px;
  height: 120px;
  display: block;
}

.avatar-uploaderVideo ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploaderVideo .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-iconVideo {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatarVideo {
  width: 150px;
  height: 120px;
  display: block;
}

.tabPaneOnetop {
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tabPanOnebtm {
  ::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    /* 设置拖动条颜色 */
  }

  ul {
    li {
      float: left;
      white-space: nowrap;
      display: block;
      margin-bottom: 5px;
    }
  }
}

// .tabPanOnebtm::-webkit-scrollbar {
//     width: 6px; /* 设置滚动条宽度 */
//     height: 10px; /* 设置滚动条高度 */
// }

.el-checkbox:last-of-type {
  margin-right: 3px;
}

.video {
  width: 240px;
  height: 130px;
  margin-right: 16px;
}

.tabPanTwobtm {
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  // justify-content: space-between;
  .vrImg {
    padding: 17px;
    margin-top: 10px;
    display: flex;
    margin-left: 30px;
    width: 300px;
    height: 180px;

    img {
      width: 75%;
      height: 100%;
    }
  }
}

.proImg {
  width: 80px;
  height: 80px;
}

.cabinetTop {
  width: 100%;
  height: 30px;
  display: flex;
}

.middelTop {
  display: flex;
  height: 30px;
  width: 100%;
  text-align: center;
  line-height: 30px;

  h2 {
    margin: 0;
    flex: 1;
  }

  .icon {
    display: flex;
    width: 28%;
    justify-content: space-between;

    span {
      flex: 1;
      font-size: 16px;
    }

    .btn {
      padding: 0;
    }

    i {
      font-size: 15px;
    }
  }
}

.cabinetMiddle {
  display: flex;
  position: relative;
  margin-top: 10px;

  .middleLeft {
    position: relative;
    width: 70%;
    height: 65%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .middleRight {
    width: 28%;
    height: 99%;
    display: flex;
    position: absolute;
    margin-left: 72%;

    .rightOne {
      width: 50%;
      height: 100%;
      position: relative;

      ul {
        position: absolute;
        float: left;
        width: 100%;
        height: 100%;
        margin: 0;

        li {
          width: 100%;
          height: 24%;
          display: block;

          img {
            object-fit: fill;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .rightTwo {
      width: 50%;
      height: 100%;
      position: relative;

      ul {
        position: absolute;
        float: left;
        width: 100%;
        height: 100%;
        margin: 0;

        li {
          position: relative;
          width: 100%;
          height: 24%;
          display: block;

          img {
            object-fit: fill;
            width: 100%;
            height: 100%;
          }

          .detele {
            position: absolute;
            top: 5px;
            right: 10px;
            z-index: 9;

            .deleBtn {
              position: absolute;
              top: 3px;
              right: 0px;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.cabinetBottom {
  width: 100%;
  height: 100px;
  margin-top: 15px;
  background-color: #e5e5e5;

  ul {
    // overflow-x: auto;
    // white-space:nowrap;
    // overflow-y: hidden;
    width: 100%;
    height: 100px;

    //   ul::-webkit-scrollbar {
    //     /* 设置滚动条宽度 */
    //     width: 100%;
    //     height: 20px;
    //     /* 设置滚动条背景色 */
    //     background-color: red;
    // }
    // ul::-webkit-scrollbar-thumb {
    //   /*滚动条里面小方块*/
    //   border-radius: 10px;
    //   height: 20px;
    //   width: 20px;
    //   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    //   // background: #E5E5E5;
    //   background: red;
    // }
    li {
      // float:left;
      width: 160px;
      height: 90px;
      margin-right: 10px;
      white-space: nowrap;
      display: inline-block;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// .cabinetBottom::-webkit-scrollbar {
//     /* 设置滚动条宽度 */
//     width: 100%;
//     height: 20px;
//     /* 设置滚动条背景色 */
//     background-color: red;
// }

.newInternalTop {
  width: 100%;
  height: 50px;
  display: flex;
  line-height: 50px;
  justify-content: space-around;

  h2 {
    margin: 0;
  }

  .topThree {
    .btn {
      margin: 0;
      padding: 0 5px;
    }
  }
}

.interImage {
  position: relative;

  .interSmallImage {
    position: absolute;
  }
}

.newInternalMiddle {
  display: flex;
  justify-content: space-between;

  .middleLeft {
    position: relative;
    width: 82%;
    height: 81%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .middleRight {
    width: 13%;
    margin-left: 77%;
    display: flex;
    position: absolute;

    ul {
      li {
        position: relative;
        width: 125px;
        height: 95px;
        margin-bottom: 10px;

        img {
          width: 100%;
          height: 100%;
        }

        .detele {
          position: absolute;
          top: 5px;
          right: 10px;
          // width: 20px;
          // height: 20px;
          // border-radius: 20px;
          // background-color: #ffffff;
          // text-align: center;
          // line-height: 20px;
          z-index: 9;

          .deleBtn {
            position: absolute;
            top: 5px;
            right: 10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            padding: 0;
          }
        }
      }
    }
  }
}

.internalTop {
  display: flex;
  height: 40px;
  line-height: 40px;

  h2 {
    flex: 1;
    margin: 0;
    text-align: center;
  }

  .el-button {
    margin: 0 5px;
    padding: 0;
  }
}

.internalMiddle {
  display: flex;
  height: 50%;

  .middleLeft {
    position: relative;
    width: 62.5%;
    height: -50%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .middleRight {
    width: 33.5%;
    height: 100%;
    margin-left: 30px;

    .rightBox {
      width: 100%;
      height: 158px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.internalBottom {
  width: 100%;
  height: 100px;
  display: flex;

  .bottomBox {
    width: 100%;
    height: 100px;
    margin-top: 15px;
    background-color: #e5e5e5;

    ul {
      overflow: hidden;

      li {
        float: left;
        width: 160px;
        height: 90px;
        margin-right: 10px;
        white-space: nowrap;
        display: inline-block;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.tabPanThreebtm {
  .tabPanUl {
    width: 100%;
    height: 100%;

    li {
      float: left;
      width: 150px;
      // height:100px;
      margin-left: 20px;
      white-space: nowrap;
      display: block;
      margin-bottom: 5px;

      img {
        width: 150px;
        height: 100px;
      }
    }
  }
}

.mainPublishDialog /deep/ .el-dialog__body {
  height: 70vh;
  overflow: scroll;
}

.mainPublishDialog /deep/ .el-dialog {
  margin-top: 7vh !important;
}

/deep/ .el-dialog__wrapper {
  overflow: hidden;
}

.tabsInput /deep/ .el-color-picker__icon,
.el-input,
.el-textarea {
  width: 100% !important;
}

.tabsInput /deep/ .el-input__inner {
  width: 100% !important;
}

/deep/ .el-dialog {
  margin-top: 8vh !important;
}

/deep/ .el-dialog__body {
  max-height: 70vh !important;
  overflow-y: scroll !important;
}
</style>
